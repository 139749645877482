import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import SUPPLIER_TYPES from '../../types/supplier.types';
import { getOffset } from '../../../utility/Utils';

// ** Initial State
const initialState = {
  suppliers: [],
  productsPending: [],
  totalProductsPending: 0,
  selectedSupplier: null,
  selectedSupplierId: null,
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    search: '',
    supplierType: null,
    orderBy: 'name',
    orderDirection: OrderDirection.ASC,
  },
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_TYPES.GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.suppliers,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case SUPPLIER_TYPES.CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        selectedSupplierId: action.payload,
      };
    case SUPPLIER_TYPES.ON_SUPPLIERS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case SUPPLIER_TYPES.ON_SUPPLIERS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case SUPPLIER_TYPES.ON_SUPPLIER_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case SUPPLIER_TYPES.ON_SUPPLIER_TYPE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          supplierType: action.payload,
        },
      };
    }
    case SUPPLIER_TYPES.ON_SUPPLIERS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case SUPPLIER_TYPES.ON_SUPPLIERS_ORDER_BY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderBy: action.payload,
        },
      };
    }
    case SUPPLIER_TYPES.GET_SUPPLIER_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedSupplier: action.payload,
        selectedSupplierId: null,
      };
    }
    case SUPPLIER_TYPES.GET_SUPPLIER_PENDING_PRODUCTS_SUCCESS: {
      return {
        ...state,
        productsPending: action.data,
        totalProductsPending: action.total,
      };
    }
    default:
      return state;
  }
};

export default supplierReducer;
