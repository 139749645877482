export const OrderStatus = Object.freeze({
  NEW: 'new-order',
  PROCESSING: 'processing',
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  //following statuses are for pre-migration orders
  ON_HOLD: 'on-hold',
  CANCELLED_CLIENT: 'cancelled-client',
  CANCELLED_MOBARTA: 'cancelled-mobarta',
  IN_STORAGE: 'in-storage',
  IN_STORAGE_PARTIAL: 'in-storage-partial',
  TO_BE_DELIVERED: 'to-be-delivered',
  PARTIAL_SHIPPING_CONFIRMED: 'partial-shipping-confirmed',
  PROCESSING_SUPPLIER: 'processing-supplier',
  RETURNED: 'returned',
});
