import COMPLAINT_TYPES from '../../types/complaints.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';

// ** Initial State
const initialState = {
  complaints: [],
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    search: '',
    orderBy: 'createdOn',
    orderDirection: OrderDirection.DESC,
    complaintType: null,
    complaintStatus: null,
  },
};

const complaintReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLAINT_TYPES.GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: action.complaints,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case COMPLAINT_TYPES.ON_COMPLAINTS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case COMPLAINT_TYPES.ON_COMPLAINTS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case COMPLAINT_TYPES.ON_COMPLAINTS_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case COMPLAINT_TYPES.ON_COMPLAINTS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case COMPLAINT_TYPES.ON_COMPLAINT_STATUS_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          complaintStatus: action.payload,
        },
      };
    }
    case COMPLAINT_TYPES.ON_COMPLAINT_TYPE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          complaintType: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default complaintReducer;
