const COMPLAINT_TYPES = {
  GET_COMPLAINTS_SUCCESS: 'GET_COMPLAINTS_SUCCESS',
  GET_COMPLAINT_BY_ID_SUCCESS: 'GET_COMPLAINT_BY_ID_SUCCESS',
  ON_COMPLAINTS_PAGE_CHANGE: 'ON_COMPLAINTS_PAGE_CHANGE',
  ON_COMPLAINTS_ROWS_PER_PAGE_CHANGE: 'ON_COMPLAINTS_ROWS_PER_PAGE_CHANGE',
  ON_COMPLAINTS_SEARCH: 'ON_COMPLAINTS_SEARCH',
  ON_COMPLAINTS_SORT: 'ON_COMPLAINTS_SORT',
  ON_COMPLAINTS_ORDER_BY_CHANGE: 'ON_COMPLAINTS_ORDER_BY_CHANGE',
  ON_COMPLAINT_STATUS_CHANGE: 'ON_COMPLAINT_STATUS_CHANGE',
  ON_COMPLAINT_TYPE_CHANGE: 'ON_COMPLAINT_TYPE_CHANGE',
};

export default COMPLAINT_TYPES;
