export const Subject = Object.freeze({
  ORDERS: 'Orders',
  ORDER: 'Order',
  SUPPLIERS: 'Suppliers',
  SUPPLIER: 'Supplier',
  USER: 'User',
  USERS: 'Users',
  PRODUCTS: 'Products',
  WAREHOUSE: 'Warehouse',
  PACKAGES_TO_BE_CONFIRMED: 'Packages-To-Be-Confirmed',
  PACKAGES_TO_BE_DELIVERED: 'Packages-To-Be-Delivered',
  LIVE_LIST: 'Live',
  LIVE_SUPPLIER: 'Live-Supplier',
  SHIPPINGS: 'Shippings',
  SHIPPING: 'Shipping',
  REPORTS: 'Reports',
  NOTIFICATIONS: 'Notifications',
  COMPLAINTS: 'Complaints',
});
