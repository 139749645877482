export const PackageStatus = Object.freeze({
  UNASSIGNED: 'unassigned',
  ON_HOLD: 'on-hold',
  IN_STORAGE: 'in-storage',
  TO_BE_CONFIRMED: 'to-be-confirmed',
  TO_BE_DELIVERED: 'to-be-delivered',
  IN_TRANSIT: 'in-transit',
  DELIVERED: 'delivered',
  POSTPONED: 'postponed',
  RETURNED: 'returned',
});
