const ORDER_TYPES = {
  GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  ON_ORDERS_PAGE_CHANGE: 'ON_ORDERS_PAGE_CHANGE',
  ON_ORDERS_ROWS_PER_PAGE_CHANGE: 'ON_ORDERS_ROWS_PER_PAGE_CHANGE',
  ON_ORDER_STATUS_CHANGE: 'ON_ORDER_STATUS_CHANGE',
  ON_ORDER_PAYMENT_STATUS_CHANGE: 'ON_ORDER_PAYMENT_STATUS_CHANGE',
  ON_ORDERS_PERIOD_CHANGE: 'ON_ORDERS_PERIOD_CHANGE',
  ON_ORDER_SEARCH: 'ON_ORDER_SEARCH',
  ON_ORDERS_SORT: 'ON_ORDERS_SORT',
  ON_ORDERS_ORDER_BY_CHANGE: 'ON_ORDERS_ORDER_BY_CHANGE',
};

export default ORDER_TYPES;
