// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import user from './user';
import navbar from './navbar';
import layout from './layout';
import order from './order';
import product from './product';
import supplier from './supplier';
import request from './request';
import profile from './profile';
import warehouse from './warehouse';
import confirmedPackages from './packagesToBeConfirmed';
import deliveredPackages from './packagesToBeDelivered';
import productsLive from './productsLive';
import shipping from './shipping';
import report from './report';
import notification from './notification';
import supplierSnapshot from './supplierSnapshot';
import complaint from './complaint';

const rootReducer = combineReducers({
  auth,
  user,
  navbar,
  layout,
  order,
  product,
  supplier,
  request,
  profile,
  warehouse,
  confirmedPackages,
  deliveredPackages,
  productsLive,
  profile,
  shipping,
  report,
  notification,
  supplierSnapshot,
  complaint,
});

export default rootReducer;
