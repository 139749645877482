const SUPPLIER_TYPES = {
  GET_SUPPLIERS_SUCCESS: 'GET_SUPPLIERS_SUCCESS',
  GET_SUPPLIER_BY_ID_SUCCESS: 'GET_SUPPLIER_BY_ID_SUCCESS',
  CREATE_SUPPLIER_SUCCESS: 'CREATE_SUPPLIER_SUCCESS',
  ON_SUPPLIERS_PAGE_CHANGE: 'ON_SUPPLIERS_PAGE_CHANGE',
  ON_SUPPLIERS_ROWS_PER_PAGE_CHANGE: 'ON_SUPPLIERS_ROWS_PER_PAGE_CHANGE',
  ON_SUPPLIER_SEARCH: 'ON_SUPPLIER_SEARCH',
  ON_SUPPLIER_TYPE_CHANGE: 'ON_SUPPLIER_TYPE_CHANGE',
  ON_SUPPLIERS_SORT: 'ON_SUPPLIERS_SORT',
  ON_SUPPLIERS_ORDER_BY_CHANGE: 'ON_SUPPLIERS_ORDER_BY_CHANGE',
  GET_SUPPLIER_PENDING_PRODUCTS_SUCCESS: 'GET_SUPPLIER_PENDING_PRODUCTS_SUCCESS',
};

export default SUPPLIER_TYPES;
