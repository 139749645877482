import { Actions } from '../../../constants/acl/actions';
import { Subject } from '../../../constants/acl/subject';

export const operatorRules = [
  {
    action: Actions.READ,
    subject: Subject.ORDERS,
  },
  {
    action: Actions.READ,
    subject: Subject.ORDER,
  },
  {
    action: Actions.UPDATE,
    subject: Subject.ORDER,
  },
  {
    action: Actions.READ,
    subject: Subject.PACKAGES_TO_BE_CONFIRMED,
  },
  {
    action: Actions.UPDATE,
    subject: Subject.PACKAGES_TO_BE_CONFIRMED,
  },
  {
    action: Actions.READ,
    subject: Subject.NOTIFICATIONS,
  },
  {
    action: Actions.READ,
    subject: Subject.COMPLAINTS,
  },
];
