import { Actions } from '../../../constants/acl/actions';
import { Subject } from '../../../constants/acl/subject';

export const warehouseRules = [
  {
    action: Actions.READ,
    subject: Subject.WAREHOUSE,
  },
  {
    action: Actions.CREATE,
    subject: Subject.WAREHOUSE,
  },
  {
    action: Actions.UPDATE,
    subject: Subject.WAREHOUSE,
  },
  {
    action: Actions.READ,
    subject: Subject.SUPPLIERS,
  },
  {
    action: Actions.READ,
    subject: Subject.LIVE_SUPPLIER,
  },
  {
    action: Actions.READ,
    subject: Subject.PACKAGES_TO_BE_DELIVERED,
  },
  {
    action: Actions.READ,
    subject: Subject.PACKAGES_TO_BE_CONFIRMED,
  },
  {
    action: Actions.READ,
    subject: Subject.SHIPPINGS,
  },
  {
    action: Actions.READ,
    subject: Subject.SHIPPING,
  },
  {
    action: Actions.CREATE,
    subject: Subject.SHIPPING,
  },
  {
    action: Actions.UPDATE,
    subject: Subject.SHIPPING,
  },
];
