import PROFILE_TYPES from '../../types/profile.types';

const initialState = {
  profile: null,
  role: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_TYPES.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.payload.profile,
        role: action.payload.role,
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
