export const ProductStatus = Object.freeze({
  NEW: 'new',
  IN_STORAGE: 'in-storage',
  REQUESTED: 'requested',
  IN_TRANSIT: 'in-transit',
  DELIVERED: 'delivered',
  RETURNED: 'returned',
  CANCELLED: 'cancelled',
  POSTPONED: 'postponed',
  RETURNED: 'returned',
});
