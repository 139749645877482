import ORDER_TYPES from '../../types/order.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { OrderStatus } from '../../../constants/enum-types/order-status';
import { calculateOrderMinimumDepositValue } from '../../utils';
import { getOffset } from '../../../utility/Utils';

// ** Initial State
const initialState = {
  orders: [],
  selectedOrder: null,
  history: [],
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    orderStatus: null,
    paymentStatus: null,
    period: '',
    search: '',
    orderBy: 'dateCreated',
    orderDirection: OrderDirection.DESC,
  },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_TYPES.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...action.selectedOrder,
          minDeposit: action.selectedOrder.orderStatus === OrderStatus.NEW ? calculateOrderMinimumDepositValue(action.selectedOrder) : null,
        },
      };
    case ORDER_TYPES.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.orders,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case ORDER_TYPES.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        selectedOrder: null,
      };
    case ORDER_TYPES.ON_ORDERS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDERS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDER_STATUS_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          orderStatus: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDER_PAYMENT_STATUS_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          paymentStatus: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDERS_PERIOD_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          period: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDER_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDERS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case ORDER_TYPES.ON_ORDERS_ORDER_BY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderBy: action.payload,
        },
      };
    }
    case ORDER_TYPES.GET_ORDER_HISTORY_SUCCESS: {
      return {
        ...state,
        history: action.history,
      };
    }
    default:
      return state;
  }
};

export default orderReducer;
