const SUPPLIER_SNAPSHOT_TYPES = {
  GET_SUPPLIER_SNAPSHOTS_SUCCESS: 'GET_SUPPLIER_SNAPSHOTS_SUCCESS',
  GET_SUPPLIER_SNAPSHOT_BY_ID_SUCCESS: 'GET_SUPPLIER_SNAPSHOT_BY_ID_SUCCESS',
  CREATE_SUPPLIER_SNAPSHOT_SUCCESS: 'CREATE_SUPPLIER_SNAPSHOT_SUCCESS',
  ON_SUPPLIER_SNAPSHOTS_PAGE_CHANGE: 'ON_SUPPLIER_SNAPSHOTS_PAGE_CHANGE',
  ON_SUPPLIER_SNAPSHOTS_ROWS_PER_PAGE_CHANGE: 'ON_SUPPLIER_SNAPSHOTS_ROWS_PER_PAGE_CHANGE',
  ON_SUPPLIER_SNAPSHOTS_SORT: 'ON_SUPPLIER_SNAPSHOTS_SORT',
};

export default SUPPLIER_SNAPSHOT_TYPES;
