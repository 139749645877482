import { PaginationConfig, reportYearOptions } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';
import REPORTS_TYPES from '../../types/reports.types';

const initialState = {
  series: [
    { name: 'Profit', data: [0] },
    { name: 'Cheltuieli', data: [0] },
  ],
  data: [],
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    year: reportYearOptions[0].value,
  },
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_TYPES.GET_REPORTS_SUCCESS:
      return {
        ...state,
        series: action.series,
        data: action.data,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case REPORTS_TYPES.ON_REPORTS_PAGE_CHANGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    case REPORTS_TYPES.ON_REPORTS_ROWS_PER_PAGE_CHANGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    case REPORTS_TYPES.ON_REPORTS_YEAR_CHANGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          year: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reportReducer;
