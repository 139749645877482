import PRODUCT_TYPES from '../../types/product.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';

const initialState = {
  products: [],
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    search: '',
    orderBy: 'createdOn',
    orderDirection: OrderDirection.DESC,
    supplier: null,
  },
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPES.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        meta: { ...state.meta, total: action.total },
      };
    case PRODUCT_TYPES.ON_PRODUCTS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case PRODUCT_TYPES.ON_PRODUCTS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case PRODUCT_TYPES.ON_PRODUCT_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case PRODUCT_TYPES.ON_PRODUCTS_SUPPLIER_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          supplier: action.payload,
        },
      };
    }
    case PRODUCT_TYPES.ON_PRODUCTS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case PRODUCT_TYPES.ON_PRODUCTS_ORDER_BY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderBy: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default productReducer;
