const PRODUCT_TYPES = {
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  ON_PRODUCTS_PAGE_CHANGE: 'ON_PRODUCTS_PAGE_CHANGE',
  ON_PRODUCTS_ROWS_PER_PAGE_CHANGE: 'ON_PRODUCTS_ROWS_PER_PAGE_CHANGE',
  ON_PRODUCTS_SUPPLIER_CHANGE: 'ON_PRODUCTS_SUPPLIER_CHANGE',
  ON_PRODUCT_SEARCH: 'ON_PRODUCT_SEARCH',
  ON_PRODUCTS_SORT: 'ON_PRODUCTS_SORT',
  ON_PRODUCTS_ORDER_BY_CHANGE: 'ON_PRODUCTS_ORDER_BY_CHANGE',
};

export default PRODUCT_TYPES;
