import NOTIFICATION_TYPES from '../../types/notification.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';

// ** Initial State
const initialState = {
  notifications: [],
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    orderDirection: OrderDirection.DESC,
  },
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case NOTIFICATION_TYPES.ON_NOTIFICATIONS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case NOTIFICATION_TYPES.ON_NOTIFICATIONS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case NOTIFICATION_TYPES.ON_NOTIFICATIONS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default notificationsReducer;
