import { Actions } from '../../../constants/acl/actions';
import { Subject } from '../../../constants/acl/subject';

export const rules = [
  {
    action: 'manage',
    subject: 'all',
  },
  {
    inverted: true,
    action: Actions.READ,
    subject: Subject.LIVE_LIST,
  },
  {
    inverted: true,
    action: Actions.READ,
    subject: Subject.LIVE_SUPPLIER,
  },
];
