import USER_TYPES from '../../types/user.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';

const initialState = {
  users: [],
  selectedUserId: null,
  selectedUser: null,
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    search: '',
    orderBy: 'firstName',
    role: null,
    orderDirection: OrderDirection.ASC,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.GET_USER_BY_ID_SUCCESS: {
      return { ...state, selectedUser: action.payload };
    }
    case USER_TYPES.CREATE_USER_SUCCESS: {
      return {
        ...state,
        selectedUserId: action.payload,
      };
    }
    case USER_TYPES.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
        meta: {
          ...state.meta,
          total: action.total,
        },
        selectedUserId: null,
        selectedUser: null,
      };
    }
    case USER_TYPES.ON_USERS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case USER_TYPES.ON_USERS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case USER_TYPES.ON_USER_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case USER_TYPES.ON_USERS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case USER_TYPES.ON_USERS_ROLE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          role: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
