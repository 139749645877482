import REQUEST_TYPES from '../../types/request.types';

// **  Initial State
const initialState = {
  requests: [],
};

export const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TYPES.REQUEST_STARTED: {
      const existingCall = state.requests.find((request) => request.requestName === action.request.requestName);

      if (existingCall) {
        return {
          ...state,
          requests: state.requests.map((request) => {
            return request.requestName === action.request.requestName ? { ...request, inProgress: true, error: null } : request;
          }),
        };
      }

      return {
        ...state,
        requests: [...state.requests, action.request],
      };
    }
    case REQUEST_TYPES.REQUEST_FINISHED: {
      return {
        ...state,
        requests: state.requests.filter((request) => request.requestName !== action.request.requestName),
      };
    }
    case REQUEST_TYPES.REQUEST_FAILED: {
      return {
        ...state,
        requests: state.requests.map((request) => {
          return request.requestName === action.request.requestName
            ? {
                ...request,
                error: action.request.error,
                inProgress: false,
              }
            : request;
        }),
      };
    }
    default: {
      return state;
    }
  }
};

export default requestReducer;
