import { PackageStatus } from '../constants/enum-types/package';

export const calculateOrderMinimumDepositValue = (order) => {
  return order.packages[0].lineItems.reduce((total, currentLineItem) => {
    total += (+currentLineItem.deposit / 100) * +currentLineItem.total;
    return +total.toFixed(2);
  }, 0);
};

export const splitShippingPackages = (shipping) => {
  return shipping.packages.reduce(
    (result, current) => {
      if (current.packageStatus === PackageStatus.IN_TRANSIT || current.packageStatus === PackageStatus.TO_BE_DELIVERED) {
        result.inProgressPackages.push(current);
      } else {
        result.completedPackages.push(current);
      }
      return result;
    },
    { completedPackages: [], inProgressPackages: [] },
  );
};
