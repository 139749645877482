import Ability from './initialAbility';
import { isUserLoggedIn, getUserData } from '../../utility/Utils';

if (isUserLoggedIn()) {
  const userData = getUserData();

  if (userData?.ability) {
    Ability.update(userData.ability);
  }
}

export default Ability;
