import SHIPPING_TYPES from '../../types/shipping.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';
import { splitShippingPackages } from '../../utils';

// ** Initial State
const initialState = {
  shippings: [],
  selectedShipping: null,
  completedPackages: [],
  inProgressPackages: [],
  shippingDate: null,
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    shippingStatus: null,
    shippingPeriod: null,
    search: '',
    orderBy: 'createdOn',
    orderDirection: OrderDirection.DESC,
  },
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING_TYPES.GET_SHIPPINGS_SUCCESS:
      return {
        ...state,
        shippings: action.shippings,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case SHIPPING_TYPES.GET_SHIPPING_BY_ID_SUCCESS:
      const packages = splitShippingPackages(action.shipping);
      return {
        ...state,
        ...packages,
        shippingDate: null,
        selectedShipping: action.shipping,
      };
    case SHIPPING_TYPES.ON_SHIPPINGS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.ON_SHIPPINGS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.ON_SHIPPINGS_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.ON_SHIPPINGS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.ON_SHIPPINGS_ORDER_BY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderBy: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.DELETE_SHIPPING_SUCCESS: {
      return {
        ...state,
        selectedShipping: null,
      };
    }
    case SHIPPING_TYPES.ON_SHIPPINGS_STATUS_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          shippingStatus: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.ON_SHIPPINGS_PERIOD_CHANGE: {
      return {
        ...state,
        selectedShipping: null,
        meta: {
          ...state.meta,
          shippingPeriod: action.payload,
        },
      };
    }
    case SHIPPING_TYPES.ON_SHIPPING_DATE_SET: {
      return {
        ...state,
        shippingDate: action.payload,
      };
    }
    default:
      return state;
  }
};

export default shippingReducer;
