// ** Imports createContext function
import { createContext, useState } from 'react';

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react';

import InitialAbility from '../../configs/acl/ability';
import { Ability } from '@casl/ability';

// ** Create Context
export const AbilityContext = createContext({
  ability: {},
  setAbility: (newAbility) => {},
});

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = ({ children }) => {
  const [ability, setAbility] = useState(InitialAbility);

  const setNewAbility = (data) => {
    const newAbility = new Ability(data);
    setAbility(newAbility);
  };

  return <AbilityContext.Provider value={{ ability, setAbility: setNewAbility }}>{children}</AbilityContext.Provider>;
};
