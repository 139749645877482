const PACKAGE_TYPES = {
  GET_WAREHOUSE_SUCCESS: 'GET_WAREHOUSE_SUCCESS',
  GET_WAREHOUSE_PACKAGE_BY_ID_SUCCESS: 'GET_WAREHOUSE_PACKAGE_BY_ID_SUCCESS',
  ON_WAREHOUSE_PAGE_CHANGE: 'ON_WAREHOUSE_PAGE_CHANGE',
  ON_WAREHOUSE_ROWS_PER_PAGE_CHANGE: 'ON_WAREHOUSE_ROWS_PER_PAGE_CHANGE',
  ON_WAREHOUSE_SEARCH: 'ON_WAREHOUSE_SEARCH',
  ON_WAREHOUSE_SORT: 'ON_WAREHOUSE_SORT',
  ON_WAREHOUSE_ORDER_BY_CHANGE: 'ON_WAREHOUSE_ORDER_BY_CHANGE',
  ON_WAREHOUSE_CITY_CHANGE: 'ON_WAREHOUSE_CITY_CHANGE',
  ON_WAREHOUSE_COUNTY_CHANGE: 'ON_WAREHOUSE_COUNTY_CHANGE',
  GET_PACKAGES_TO_BE_CONFIRMED_SUCCESS: 'GET_PACKAGES_TO_BE_CONFIRMED_SUCCESS',
  GET_PACKAGE_TO_BE_CONFIRMED_BY_ID_SUCCESS: 'GET_PACKAGE_TO_BE_CONFIRMED_BY_ID_SUCCESS',
  ON_PACKAGES_TO_BE_CONFIRMED_PAGE_CHANGE: 'ON_PACKAGES_TO_BE_CONFIRMED_PAGE_CHANGE',
  ON_PACKAGES_TO_BE_CONFIRMED_ROWS_PER_PAGE_CHANGE: 'ON_PACKAGES_TO_BE_CONFIRMED_ROWS_PER_PAGE_CHANGE',
  ON_PACKAGES_TO_BE_CONFIRMED_SEARCH: 'ON_PACKAGES_TO_BE_CONFIRMED_SEARCH',
  ON_PACKAGES_TO_BE_CONFIRMED_SORT: 'ON_PACKAGES_TO_BE_CONFIRMED_SORT',
  ON_PACKAGES_TO_BE_CONFIRMED_ORDER_BY_CHANGE: 'ON_PACKAGES_TO_BE_CONFIRMED_ORDER_BY_CHANGE',
  ON_PACKAGES_TO_BE_CONFIRMED_CITY_CHANGE: 'ON_PACKAGES_TO_BE_CONFIRMED_CITY_CHANGE',
  ON_PACKAGES_TO_BE_CONFIRMED_COUNTY_CHANGE: 'ON_PACKAGES_TO_BE_CONFIRMED_COUNTY_CHANGE',
  GET_PACKAGES_TO_BE_DELIVERED_SUCCESS: 'GET_PACKAGES_TO_BE_DELIVERED_SUCCESS',
  GET_PACKAGE_TO_BE_DELIVERED_BY_ID_SUCCESS: 'GET_PACKAGE_TO_BE_DELIVERED_BY_ID_SUCCESS',
  ON_PACKAGES_TO_BE_DELIVERED_PAGE_CHANGE: 'ON_PACKAGES_TO_BE_DELIVERED_PAGE_CHANGE',
  ON_PACKAGES_TO_BE_DELIVERED_ROWS_PER_PAGE_CHANGE: 'ON_PACKAGES_TO_BE_DELIVERED_ROWS_PER_PAGE_CHANGE',
  ON_PACKAGES_TO_BE_DELIVERED_SEARCH: 'ON_PACKAGES_TO_BE_DELIVERED_SEARCH',
  ON_PACKAGES_TO_BE_DELIVERED_SORT: 'ON_PACKAGES_TO_BE_DELIVERED_SORT',
  ON_PACKAGES_TO_BE_DELIVERED_ORDER_BY_CHANGE: 'ON_PACKAGES_TO_BE_DELIVERED_ORDER_BY_CHANGE',
  ON_PACKAGES_TO_BE_DELIVERED_CITY_CHANGE: 'ON_PACKAGES_TO_BE_DELIVERED_CITY_CHANGE',
  ON_PACKAGES_TO_BE_DELIVERED_COUNTY_CHANGE: 'ON_PACKAGES_TO_BE_DELIVERED_COUNTY_CHANGE',
};

export default PACKAGE_TYPES;
