import { Actions } from '../../../constants/acl/actions';
import { Subject } from '../../../constants/acl/subject';

export const deliveryRules = [
  {
    action: Actions.READ,
    subject: Subject.SHIPPINGS,
  },
  {
    action: Actions.UPDATE,
    subject: Subject.SHIPPING,
  },
];
