const USER_TYPES = {
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  ON_USERS_PAGE_CHANGE: 'ON_USERS_PAGE_CHANGE',
  ON_USERS_ROWS_PER_PAGE_CHANGE: 'ON_USERS_ROWS_PER_PAGE_CHANGE',
  ON_USER_SEARCH: 'ON_USER_SEARCH',
  ON_USERS_SORT: 'ON_USERS_SORT',
  ON_USERS_ROLE_CHANGE: 'ON_USERS_ROLE_CHANGE',
};

export default USER_TYPES;
