const SHIPPING_TYPES = {
  GET_SHIPPINGS_SUCCESS: 'GET_SHIPPINGS_SUCCESS',
  GET_SHIPPING_BY_ID_SUCCESS: 'GET_SHIPPING_BY_ID_SUCCESS',
  ON_SHIPPINGS_PAGE_CHANGE: 'ON_SHIPPINGS_PAGE_CHANGE',
  ON_SHIPPINGS_ROWS_PER_PAGE_CHANGE: 'ON_SHIPPINGS_ROWS_PER_PAGE_CHANGE',
  ON_SHIPPINGS_SEARCH: 'ON_SHIPPINGS_SEARCH',
  ON_SHIPPINGS_SORT: 'ON_SHIPPINGS_SORT',
  ON_SHIPPINGS_ORDER_BY_CHANGE: 'ON_SHIPPINGS_ORDER_BY_CHANGE',
  ON_SHIPPINGS_STATUS_CHANGE: 'ON_SHIPPINGS_STATUS_CHANGE',
  ON_SHIPPINGS_PERIOD_CHANGE: 'ON_SHIPPINGS_PERIOD_CHANGE',
  ON_SHIPPING_DATE_SET: 'ON_SHIPPING_DATE_SET',
  DELETE_SHIPPING_SUCCESS: 'DELETE_SHIPPING_SUCCESS',
};

export default SHIPPING_TYPES;
