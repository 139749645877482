import PRODUCTS_LIVE_TYPES from '../../types/productsLive.types';

const initialState = {
  productsLive: [],
  total: 0,
};

const productsLiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_LIVE_TYPES.GET_PRODUCTS_LIVE_SUCCESS:
      return {
        ...state,
        productsLive: action.productsLive,
        total: action.total,
      };
    default:
      return {
        ...state,
      };
  }
};

export default productsLiveReducer;
