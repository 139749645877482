import { AddonType } from './enum-types/addon-type';
import { ComplaintStatus } from './enum-types/complaint-status';
import { ComplaintTypes } from './enum-types/complaint-type';
import { OrderStatus } from './enum-types/order-status';
import { PaymentStatus } from './enum-types/payment-status';
import { ProductStatus } from './enum-types/products-status';
import { ShippingStatus } from './enum-types/shipping-status';

export const Constants = {
  verticalIconDropdownMenuSize: 18,
  attachmentModalCloseIconStrokeWidth: 3,
  attachmentModalCloseIconSize: 24,
  attachmentsNumberOfImages: 3,
  searchLabel: 'ID Comanda / Nume client ...',
  plusIconMenuSize: 12,
  listsSize: 3,
  listSendingDefaultHour: '11:00',
  supplierDetailsTab: '',
  supplierLiveTab: '/live',
  supplierPendingTab: '/pending',
  supplierListsTab: '/lists',
  APIListHourTimeFormat: 'HH:mm',
  UIListHourTimeFormat: 'hh:mma',
};

export const inputValidations = {
  password: {
    required: {
      value: true,
      message: 'general.input.required',
    },
  },
  email: {
    required: { value: true, message: 'general.input.required' },
    maxLength: {
      value: 50,
      message: 'general.input.max.length',
    },
    pattern: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: 'general.input.email',
    },
  },
  supplierSku: {
    required: {
      value: true,
      message: 'products.association.add.sku',
    },
  },
  supplierValue: {
    required: {
      value: true,
      message: 'products.association.add.supplier',
    },
    pattern: {
      value: /[0-9]/,
      message: 'products.association.supplier.id.nan',
    },
  },
  notes: {
    required: { value: true, message: 'general.input.required' },
    maxLength: {
      value: 1000,
      message: 'general.input.max.length',
    },
  },
};

export const addSupplierBreadcrumbRoutes = [
  { route: '/supplier-list', label: 'suppliers', active: false },
  { route: '', label: 'suppliers.add', active: true },
];

export const addShippingBreadcrumbRoutes = [
  { route: '/shippings', label: 'shippings', active: false },
  { route: '', label: 'shippings.add', active: true },
];

export const updateShippingBreadcrumbRoutes = [
  { route: '/shippings', label: 'shippings', active: false },
  { route: '/shipping/:routeId', label: 'shipping.breadcrumb.id', active: false },
  { route: '', label: 'shippings.update', active: true },
];

export const selectedSupplierBreadcrumbRoutes = [
  { route: '/supplier-list', label: 'suppliers', active: false },
  { route: '', label: 'general.entity.name', active: true },
];

export const selectedUserBreadcrumbRoutes = [
  { route: '/users', label: 'users', active: false },
  { route: '', label: 'general.entity.name', active: true },
];

export const orderHistoryBreadcrumbRoutes = [
  { route: '/order-list', label: 'orders', active: false },
  { route: `/orders/:routeId`, label: 'order.breadcrumb.id', active: false },
  { route: '', label: 'order.history', active: true },
];

export const addUserBreadcrumbRoutes = [
  { route: '/users', label: 'users', active: false },
  { route: '', label: 'users.add', active: true },
];

export const supplierTypesOptions = [
  { value: 'stock', label: 'products.stoc' },
  { value: 'on_order', label: 'suppliers.types.onDemand' },
  { value: 'custom_furniture', label: 'suppliers.types.customFurniture' },
];

export const supplierLocationTypeOptions = [
  { value: 'main', label: 'suppliers.location.type.main' },
  { value: 'mixed', label: 'suppliers.location.type.mixed' },
  { value: 'warehouse', label: 'suppliers.location.type.warehouse' },
];

export const orderStatus = [
  { value: OrderStatus.NEW, label: 'orders.status.new-order' },
  { value: OrderStatus.PROCESSING, label: 'orders.status.processing' },
  { value: OrderStatus.CONFIRMED, label: 'orders.status.confirmed' },
  { value: OrderStatus.COMPLETED, label: 'orders.status.completed' },
  { value: OrderStatus.CANCELLED, label: 'orders.status.cancelled' },
  { value: OrderStatus.FAILED, label: 'orders.status.failed' },
];

export const oldOrderStatus = [
  { value: OrderStatus.ON_HOLD, label: 'orders.status.on-hold' },
  { value: OrderStatus.CANCELLED_CLIENT, label: 'orders.status.cancelled-client' },
  { value: OrderStatus.CANCELLED_MOBARTA, label: 'orders.status.cancelled-mobarta' },
  { value: OrderStatus.IN_STORAGE, label: 'orders.status.in-storage' },
  { value: OrderStatus.IN_STORAGE_PARTIAL, label: 'orders.status.in-storage-partial' },
  { value: OrderStatus.TO_BE_DELIVERED, label: 'ordes.status.to-be-delivered' },
  { value: OrderStatus.PARTIAL_SHIPPING_CONFIRMED, label: 'orders.status.partial-shipping-confirmed' },
  { value: OrderStatus.PROCESSING_SUPPLIER, label: 'orders.status.processing-supplier' },
  { value: OrderStatus.RETURNED, label: 'orders.status.returned' },
];

export const productStatus = [
  { value: 'new', label: 'products.status.new' },
  { value: 'to-be-requested', label: 'products.status.to-be-requested' },
  { value: 'requested', label: 'products.status.requested' },
  { value: 'in-storage', label: 'products.status.in-storage' },
  { value: 'to-be-delivered', label: 'products.status.to-be-delivered' },
  { value: 'in-transit', label: 'products.status.in-transit' },
  { value: 'delivered', label: 'products.status.delivered' },
  { value: 'defect', label: 'products.status.defect' },
  { value: 'to-be-repaired', label: 'products.status.to-be-repaired' },
  { value: 'postponed', label: 'products.status.postponed' },
  { value: 'returned', label: 'products.status.returned' },
  { value: 'cancelled', label: 'products.status.cancelled' },
];

export const paymentStatus = [
  { value: PaymentStatus.DEPOSIT_TO_PAY, label: 'orders.payment.status.deposit-to-pay' },
  { value: PaymentStatus.UNPAID, label: 'orders.payment.status.unpaid' },
  { value: PaymentStatus.DEPOSIT_PAID, label: 'orders.payment.status.deposit-paid' },
  { value: PaymentStatus.PAID, label: 'orders.payment.status.paid' },
];

export const shippingStatus = [
  { value: ShippingStatus.NEW, label: 'shippings.status.new' },
  { value: ShippingStatus.IN_PROGRESS, label: 'shippings.status.in-progress' },
  { value: ShippingStatus.COMPLETED, label: 'shippings.status.completed' },
];

export const orderPeriod = [
  { value: 'today', label: 'orders.period.today' },
  { value: 'yesterday', label: 'orders.period.yesterday' },
  { value: 'last-3', label: 'orders.period.3.days' },
  { value: 'last-7', label: 'orders.period.7.days' },
  { value: 'last-30', label: 'orders.period.30.days' },
  { value: 'last-60', label: 'orders.period.60.days' },
  { value: 'last-90', label: 'orders.period.90.days' },
];

export const selectedOrderBreadcrumbRoutes = [
  { route: '/order-list', label: 'orders', active: false },
  { route: '', label: 'order.breadcrumb.id', active: true },
];

export const selectedShippingBreadcrumbRoutes = [
  { route: '/shippings', label: 'shippings', active: false },
  { route: '', label: 'shipping.breadcrumb.id', active: true },
];

export const selectedShippingPackageBreadcrumbRoutes = [
  { route: '/shippings', label: 'shippings', active: false },
  { route: '/shipping/:routeId/details', label: 'shipping.breadcrumb.id', active: false },
  { route: '', label: 'shipping.breadcrumb.package.id', active: true },
];

export const PaginationConfig = {
  itemsPerPage: 25,
  itemsPerPageOptions: [15, 25, 50],
};

export const automaticListTypeReccurence = [
  { value: 'weekly', label: 'supplier.list.reccurence.weekly' },
  { value: 'bimonthly', label: 'supplier.list.reccurence.weeks.two' },
  { value: 'monthly', label: 'supplier.list.reccurence.weeks.four' },
];
export const daysOfWeek = [
  { value: 'monday', label: 'general.monday' },
  { value: 'tuesday', label: 'general.tuesday' },
  { value: 'wednesday', label: 'general.wednesday' },
  { value: 'thursday', label: 'general.thursday' },
  { value: 'friday', label: 'general.friday' },
  { value: 'saturday', label: 'general.saturday' },
  { value: 'sunday', label: 'general.sunday' },
];

export const OrderDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const discountTypeOptions = [
  {
    value: AddonType.PERCENTAGE,
    label: 'order.discount.percentual',
  },
  { value: AddonType.FIXED, label: 'order.discount.fixed' },
];

export const userRoleOptions = [
  {
    value: 'admin',
    label: 'user.role.admin',
  },
  { value: 'operator', label: 'user.role.operator' },
  {
    value: 'livrator',
    label: 'user.role.livrator',
  },
  { value: 'admin-depozit', label: 'user.role.admin-depozit' },
  { value: 'furnizor', label: 'user.role.furnizor' },
];

export const packageStatus = [
  { value: 'on-hold', label: 'packages.status.on-hold' },
  { value: 'in-storage', label: 'packages.status.in-storage' },
  { value: 'to-be-confirmed', label: 'packages.status.to-be-confirmed' },
  { value: 'to-be-delivered', label: 'packages.status.to-be-delivered' },
  { value: 'in-transit', label: 'packages.status.in-transit' },
  { value: 'returned', label: 'packages.status.returned' },
  { value: 'postponed', label: 'packages.status.postponed' },
  { value: 'delivered', label: 'packages.status.delivered' },
  { value: 'cancelled', label: 'packages.status.cancelled' },
];

export const complaintTypesLabels = [
  { value: ComplaintTypes.NEW, label: 'complaint.type.new' },
  { value: ComplaintTypes.FIX, label: 'complaint.type.fix' },
  { value: ComplaintTypes.RETURN, label: 'complaint.type.return' },
];

export const complaintStatusesLabels = [
  { value: ComplaintStatus.NO_ACTION, label: 'complaint.status.no-action' },
  { value: ComplaintStatus.FIXED, label: 'complaint.status.fixed' },
  { value: ComplaintStatus.ORDERED, label: 'complaint.status.ordered' },
  { value: ComplaintStatus.RETURN_IN_STORAGE, label: 'complaint.status.return-no-action' },
  { value: ComplaintStatus.RETURN_NO_ACTION, label: 'complaint.status.return-in-storage' },
];

export const complaintStatusOptions = {
  [ComplaintTypes.RETURN]: [
    { value: ComplaintStatus.RETURN_NO_ACTION, label: 'complaint.status.return-no-action.confirm' },
    { value: ComplaintStatus.RETURN_IN_STORAGE, label: 'complaint.status.return-in-storage.confirm' },
  ],
  [ComplaintTypes.FIX]: [
    { value: ComplaintStatus.ORDERED, label: 'complaint.status.ordered' },
    { value: ComplaintStatus.FIXED, label: 'complaint.status.fixed' },
  ],
};

export const complaintTypeColors = {
  [ComplaintTypes.NEW]: {
    badge: 'light-secondary',
    text: 'secondary',
  },
  [ComplaintTypes.FIX]: {
    badge: 'light-danger',
    text: 'danger',
  },
  [ComplaintTypes.RETURN]: {
    badge: 'light-warning',
    text: 'warning',
  },
};

export const complaintStatusColors = {
  [ComplaintStatus.NO_ACTION]: {
    badge: 'light-secondary',
    text: 'secondary',
  },
  [ComplaintStatus.RETURN_NO_ACTION]: {
    badge: 'light-danger',
    text: 'danger',
  },
  [ComplaintStatus.ORDERED]: {
    badge: 'light-warning',
    text: 'warning',
  },
  [ComplaintStatus.RETURN_IN_STORAGE]: {
    badge: 'light-primary',
    text: 'primary',
  },
  [ComplaintStatus.FIXED]: {
    badge: 'light-success',
    text: 'success',
  },
};

export const packageStatusColors = {
  unassigned: {
    badge: 'light-secondary',
    text: 'secondary',
  },
  'on-hold': {
    badge: 'light-secondary',
    text: 'secondary',
  },
  'in-storage': {
    badge: 'light-secondary',
    text: 'secondary',
  },
  'to-be-confirmed': {
    badge: 'light-warning',
    text: 'warning',
  },
  'to-be-delivered': {
    badge: 'light-success',
    text: 'success',
  },
  'in-transit': {
    badge: 'light-primary',
    text: 'primary',
  },
  delivered: {
    badge: 'secondary',
    text: 'dark',
  },
  returned: {
    badge: 'light-danger',
    text: 'danger',
  },
  postponed: {
    badge: 'light-warning',
    text: 'warning',
  },
};

export const orderStatusColors = {
  'new-order': {
    badge: 'light-secondary',
    text: 'secondary',
  },
  confirmed: {
    badge: 'light-primary',
    text: 'primary',
  },
  processing: {
    badge: 'light-warning',
    text: 'warning',
  },
  cancelled: {
    badge: 'light-danger',
    text: 'danger',
  },
  completed: {
    badge: 'light-success',
    text: 'success',
  },
  failed: {
    badge: 'secondary',
    text: 'dark',
  },
};

export const productStatusColors = {
  [ProductStatus.NEW]: {
    badge: 'light-secondary',
    text: 'secondary',
  },
  [ProductStatus.DELIVERED]: {
    badge: 'light-primary',
    text: 'primary',
  },
  [ProductStatus.IN_TRANSIT]: {
    badge: 'light-warning',
    text: 'warning',
  },
  [ProductStatus.CANCELLED]: {
    badge: 'light-danger',
    text: 'danger',
  },
  [ProductStatus.IN_STORAGE]: {
    badge: 'light-success',
    text: 'success',
  },
  [ProductStatus.REQUESTED]: {
    badge: 'secondary',
    text: 'dark',
  },
  [ProductStatus.RETURNED]: {
    badge: 'light-danger',
    text: 'danger',
  },
  [ProductStatus.POSTPONED]: {
    badge: 'light-warning',
    text: 'warning',
  },
};

export const shippingStatusColors = {
  new: {
    badge: 'light-secondary',
    text: 'secondary',
  },
  'in-progress': {
    badge: 'light-warning',
    text: 'warning',
  },
  completed: {
    badge: 'light-success',
    text: 'success',
  },
};

export const paymentStatusColors = {
  [PaymentStatus.UNPAID]: {
    badge: 'light-secondary',
    text: 'secondary',
  },
  [PaymentStatus.PAID]: {
    badge: 'light-success',
    text: 'success',
  },
  [PaymentStatus.DEPOSIT_TO_PAY]: {
    badge: 'light-warning',
    text: 'warning',
  },
  [PaymentStatus.DEPOSIT_PAID]: {
    badge: 'light-success',
    text: 'success',
  },
};

export const SHIPPING_PAYMENT = '70 lei';
export const FREE_PAYMENT = '0 lei';
export const DELIVERY_DATE_MAX_DATE_DAYS_IN_FUTURE = 7;

export const reportYearOptions = [{ label: '2022', value: 2022 }];

export const reportsExpenseTypeOptions = [
  { value: 'shipping', label: 'reports.expense.type.shipping' },
  { value: 'personal', label: 'reports.expense.type.personal' },
  { value: 'mk-facebook', label: 'reports.expense.type.mk-facebook' },
  { value: 'mk-google', label: 'reports.expense.type.mk-google' },
  { value: 'other', label: 'reports.expense.type.other' },
];
