import SUPPLIER_SNAPSHOT_TYPES from '../../types/supplierSnapshot.types';
import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';

// ** Initial State
const initialState = {
  snapshots: [],
  selectedSnapshot: null,
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    orderBy: 'createdOn',
    orderDirection: OrderDirection.DESC,
  },
};

const supplierSnapshotReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_SNAPSHOT_TYPES.GET_SUPPLIER_SNAPSHOTS_SUCCESS:
      return {
        ...state,
        snapshots: action.snapshots,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case SUPPLIER_SNAPSHOT_TYPES.GET_SUPPLIER_SNAPSHOT_BY_ID_SUCCESS:
      return {
        ...state,
        selectedSnapshot: action.snapshot,
      };
    case SUPPLIER_SNAPSHOT_TYPES.ON_SUPPLIER_SNAPSHOTS_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case SUPPLIER_SNAPSHOT_TYPES.ON_SUPPLIER_SNAPSHOTS_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case SUPPLIER_SNAPSHOT_TYPES.ON_SUPPLIER_SNAPSHOTS_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default supplierSnapshotReducer;
