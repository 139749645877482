import { PaginationConfig, OrderDirection } from '../../../constants/constants';
import { getOffset } from '../../../utility/Utils';
import PACKAGE_TYPES from '../../types/package.types';

const initialState = {
  packages: [],
  selectedPackage: null,
  meta: {
    limit: PaginationConfig.itemsPerPage,
    pageSizeOptions: PaginationConfig.itemsPerPageOptions,
    offset: 0,
    total: 0,
    page: 1,
    search: '',
    orderBy: 'deliveryDate',
    orderDirection: OrderDirection.ASC,
    city: null,
    county: null,
  },
};

const packagesToBeConfirmedReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGE_TYPES.GET_PACKAGES_TO_BE_CONFIRMED_SUCCESS:
      return {
        ...state,
        packages: action.packages,
        meta: {
          ...state.meta,
          total: action.total,
        },
      };
    case PACKAGE_TYPES.GET_PACKAGE_TO_BE_CONFIRMED_BY_ID_SUCCESS:
      return {
        ...state,
        selectedPackage: action.payload,
      };
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: getOffset(action.payload, state.meta),
          page: action.payload,
        },
      };
    }
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_ROWS_PER_PAGE_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          limit: action.payload,
        },
      };
    }
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_SEARCH: {
      return {
        ...state,
        meta: {
          ...state.meta,
          offset: 0,
          page: 1,
          search: action.payload,
        },
      };
    }
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_SORT: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderDirection: action.payload,
        },
      };
    }
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_ORDER_BY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          orderBy: action.payload,
        },
      };
    }
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_CITY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          city: action.payload,
        },
      };
    }
    case PACKAGE_TYPES.ON_PACKAGES_TO_BE_CONFIRMED_COUNTY_CHANGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          county: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default packagesToBeConfirmedReducer;
