import { toast } from 'react-toastify';
import { useContext } from 'react';
import { IntlContext } from './context/Internationalization';
import { operatorRules } from '../configs/acl/rules/operator';
import { rules } from '../configs/acl/rules/admin';
import { supplierRules } from '../configs/acl/rules/supplier';
import { warehouseRules } from '../configs/acl/rules/admin-depozit';
import { deliveryRules } from '../configs/acl/rules/delivery';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));
export const setUserAbility = (role) => {
  const userData = getUserData();
  /**
   * SET rules based on user type from localstorage
   */
  let ability = [];
  switch (role) {
    case 'operator':
      ability = operatorRules;
      break;
    case 'admin':
      ability = rules;
      break;
    case 'furnizor':
      ability = supplierRules;
      break;
    case 'admin-depozit':
      ability = warehouseRules;
      break;
    case 'livrator':
      ability = deliveryRules;
      break;
    default:
      ability = [];
  }

  localStorage.setItem('userData', JSON.stringify({ ...userData, ability }));
};
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return '/access-control';
  return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

// type: 'success' | 'error' | 'warning'
export const showToast = (message, type) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
    className: `toast-${type}`,
    progressClassName: 'no-progress',
  });
};

export const getOffset = (nextPage, meta) => {
  return (nextPage - 1) * meta.limit;
};
export const capitalizeFirstLetter = (string, type) => {
  if (!string) {
    return;
  }

  if (type === 'all') {
    const strings = string.split(' ');
    const newStrings = [];
    strings.forEach((element) => {
      newStrings.push(element.charAt(0).toUpperCase() + element.slice(1));
    });
    return newStrings.join(' ');
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const setInitialValues = (values, setValue) => {
  for (const [key, value] of Object.entries(values)) {
    setValue(key, value);
  }
};

export const formatPrice = (value) => {
  const { messages } = useContext(IntlContext);
  let valueString = Number(value ? value : 0)
    .toFixed(2)
    .toString()
    .replace('.', ',');
  let index = valueString.search(',');
  while (index > 3) {
    valueString = `${valueString.substr(0, index - 3)}.${valueString.substr(index - 3)}`;
    index -= 3;
  }

  return `${valueString} ${messages['general.currency']}`;
};

export const convertToLocalTime = (date, options) => {
  const defaultOptions = { year: false, time: false };
  const opt = { ...defaultOptions, ...options };
  const localTime = new Date(date);
  const dateFormat = { month: 'short', day: 'numeric' };
  if (opt.year) {
    dateFormat.year = 'numeric';
  }
  const formattedDateArray = localTime.toLocaleDateString('ro', dateFormat).split(' ');
  formattedDateArray[1] = formattedDateArray[1].charAt(0).toUpperCase() + formattedDateArray[1].slice(1);
  const formattedDate = formattedDateArray.join(' ');

  return date ? `${formattedDate} ${opt.time ? localTime.toLocaleTimeString('ro', { hour: '2-digit', minute: '2-digit' }) : ''}` : '';
};

export const textNoWrap = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const cleanPostPayload = (payload) => {
  return Object.keys(payload)
    .filter((k) => payload[k] !== null && payload[k] !== '' && payload[k] !== undefined)
    .reduce((a, k) => ({ ...a, [k]: payload[k] }), {});
};

export const cleanPatchPayload = (payload) => {
  const newPayload = { ...payload };
  Object.keys(newPayload).forEach((k) => {
    if (newPayload[k] === '') {
      newPayload[k] = null;
    }
  });
  return newPayload;
};

export const removeDuplicateCharacters = (str, chars) => {
  let newString = str;
  for (let i = chars.length; i < newString.length - (chars.length - 1); i++) {
    if (newString.substr(i, chars.length) === chars && newString.substr(i, chars.length) === newString.substr(i - chars.length, chars.length)) {
      newString = newString.slice(0, i) + (i < newString.length - chars.length ? newString.slice(i + chars.length) : '');
      if (i < newString.length - chars.length) {
        i--;
      }
    }
  }
  return newString;
};
